<template>
  <div class="main-secondary">
    <div class="row col-12 col-md-8 my-5" v-if="!showLatestNews">
      <h1 class="f-secondary t-secondary t-800 text-gradient">The latest news</h1>
      <p>
        The world of healthcare is ever-changing, so stay ahead with TalentFind.
        From key updates across the global industry to exciting platform
        developments, keep checking back for all the latest news right here.
      </p>
    </div>
    <div class="row w-50 my-5" v-else>
      <h1 class="f-secondary t-secondary text-gradient">Latest News</h1>
    </div>
    <div>
      <button
        class="btn btn-primary border-radius-40 mr-3"
        style="padding: 10px"
        @click="showLatestNews = false"
      >
        All Categories
      </button>
      <button
        class="btn btn-outline-2 border-radius-40"
        style="padding: 10px; border-radius: 40px; border: 2px solid #ff00ff"
        @click="showLatestNews = true"
      >
        Latest News
      </button>
    </div>
    <ArticleSection :hide="true" />
    <FutureSection />
    <NewFooter />
  </div>
</template>

<script>
import ArticleSection from "./ArticlesSection";
import FutureSection from "./FutureSection";
import NewFooter from "./NewFooter";
import { mapActions } from "vuex";

export default {
  components: {
    ArticleSection,
    FutureSection,
    NewFooter,
  },
  data() {
    return {
      showLatestNews: false,
    };
  },
  methods: {
    ...mapActions(["scrollTop"]),
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
